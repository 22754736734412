import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" />
    <div className="success  settings__greyGradientBkgd">
      <h1>Success!</h1>
      <p>
        Thank you for getting in touch,
        <br />
        your form was successfully submitted.
      </p>
      <p>We will be in touch with you soon!</p>
      <Link
        className=" text-white  font-extrabold text-2xl uppercase m-8 bg-blue-light p-4 hover:bg-white hover:border-blue-light hover:text-blue-light hover:border-solid "
        to="/"
      >
        Home Page
      </Link>
    </div>
  </Layout>
)

export default SuccessPage
